@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
// Utilisation de outline au lieu de border pour éviter les redimensionnements des blocs qui font bouger le layout

$radio-checked-background: $grey-light !default;
$radio-hover-border-color: $active-color-light !default;
$radio-outline: 2px solid $active-color !default;
$radio-outline-error: 1px solid var(--color-warning-500, $color-error) !default;
$radio-border-radius: $border-radius-big !default;
$input-shadow-box: none !default;

.radio {
	display: flex;
	align-items: center;
	border: 1px solid var(--color-gray-500, $input-border-color);
	box-sizing: border-box;
	height: 100%;
	transition: border-color 0.2s ease;
	margin: 2px;
	border-radius: $radio-border-radius;
	box-shadow: $input-shadow-box;
	position: relative;

	&--checked {
		outline: $radio-outline;
		border-color: var(--color-active, $active-color);
		background: $radio-checked-background;

		.radio__icon {
			path,
			circle {
				fill: var(--color-active, $active-color);
				stroke: var(--color-active, $active-color);
			}
		}
	}

	&--error {
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 10px rgba(197, 6, 18, 0.6);
		outline: $radio-outline-error;
		border-color: var(--color-warning-500);
	}

	&--disabled {
		opacity: 0.4;
		pointer-events: none;

		.radio__label {
			cursor: default;
		}
	}

	@include gridle_state(md) {
		&:hover {
			background: none;
			outline: none;
			border-color: var(--color-active, $active-color);
		}

		&--checked {
			&:hover {
				outline-color: var(--color-active, $active-color);
				border-color: var(--color-active, $active-color);
				background: $radio-checked-background;
			}
		}

		&--error {
			&:hover {
				outline-color: var(--color-warning-500);
				border-color: var(--color-warning-500);
			}
		}

		&--disabled {
			&:hover {
				outline-color: $grey-light;
				border-color: $grey-light;
			}
		}
	}

	&__input {
		display: none;
	}

	&__label {
		display: flex;
		flex-direction: column;
		cursor: pointer;
		width: 100%;
		height: 100%;
		justify-content: center;
		box-sizing: border-box;
	}

	&__header {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;

		path,
		circle {
			stroke: $grey-dark;
		}
	}

	&__text {
		width: 100%;
	}
}
