@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.checkbox {
	border: none;
	box-sizing: border-box;
	height: 100%;
	transition: border-color 0.2s ease;

	&__input {
		display: none;
	}

	&__label {
		cursor: pointer;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}

	&__header {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
	}

	&__icon {
		display: flex;
		align-items: center;
		margin: 0 10px 0 0;

		+ .checkbox__text {
			margin-left: 0;
		}
	}

	&__text {
		margin-left: 10px;
		width: 100%;
	}

	&__helper {
		display: inline-block;
		margin-top: 10px;
	}

	&--required {
		.checkbox__text {
			&:after {
				content: "*";
			}
		}
	}

	&--error {
		&--checked {
			path,
			g {
				fill: var(--color-warning-500);
			}
		}

		.checkbox__text {
			color: var(--color-warning-500);

			.relative-link {
				color: var(--color-warning-500);
			}
		}
	}

	&--disabled {
		.checkbox__label {
			pointer-events: none;
			cursor: not-allowed;
		}
	}
}
